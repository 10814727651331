<template>
  <div class="p-2 card">
    <b-row>
      <b-col
        md="12"
        lg="12">
        <h1>Cora - <span class="font-weight-light">Aplicações</span></h1>
      </b-col>
    </b-row>
    <hr class="border-2">

    <div v-if="carregandoTabela" class="w-100 mt-5 d-flex flex-column align-items-center justify-content-center">
      <b-spinner variant="custom" class="mb-1" label="Loading..."></b-spinner>
      <span>Buscando dados...</span>
    </div>
    <section>
      <b-row class="my-2">
        <b-col md="2" class="mb-1 mb-md-0">
          <div class="d-flex flex-row align-items-center">
            <label class="mr-50">Visualizar</label>
            <b-form-select
              v-model="dadosPaginacao.quantidadeLinhasPadrao"
              size="sm"
              id="amounlinesVisualizer"
              label="option"
              @input="atualizaQuantidadeVisualizar($event)"
              :options="tabela.quantidadeLinhasVisualizar"
            />
          </div>
        </b-col>
        <b-col></b-col>
        <b-col md="3" class="d-flex justify-content-end">
          <b-form-input
            id="pesquisa"
            size="sm"
            v-model="busca.termo"
            autocomplete="off"
            placeholder="Buscar"
            @keyup="pesquisar"
          />
        </b-col>
        <b-col sm="12" class="my-2">
          <b-table
            id="listCompaniesTable"
            @context-changed="ordenarTabela"
            sticky-header="100%"
            :busy.sync="tabela.tabelaOcupada"
            :no-local-sorting="true"
            :fields="tabela.fields"
            :items="tabela.items"
          >
            <template #head(nome_aplicacao)="data">
              <feather-icon icon="GridIcon" class="mr-1-variant mt-n1-variant"></feather-icon>
              {{ data.label }}
            </template>
            <template #head(razao_social_empresa)="data">
              <feather-icon icon="BriefcaseIcon" class="mr-1-variant mt-n1-variant"></feather-icon>
              {{ data.label }}
            </template>
            <template #head(situacao_aplicacao)="data">
              <feather-icon icon="LoaderIcon" class="mr-1-variant mt-n1-variant"></feather-icon>
              {{ data.label }}
            </template>
            <template #head(data_inicio_aplicacao)="data">
              <feather-icon icon="CalendarIcon" class="mr-1-variant mt-n1-variant"></feather-icon>
              {{ data.label }}
            </template>
            <template #head(acoes)="data">
              <feather-icon icon="CheckCircleIcon" class="mr-1-variant mt-n1-variant"></feather-icon>
              {{ data.label }}
            </template>
            <template #cell(situacao_aplicacao)="row">
              <div class="text-center">
                <b-badge variant="light-success" v-if="row.item.situacao_aplicacao === applicationStatus.emAndamento">
                  {{ row.item.situacao_aplicacao }}
                </b-badge>
                <b-badge variant="light-danger" v-else-if="row.item.situacao_aplicacao === applicationStatus.cancelado">
                  {{ row.item.situacao_aplicacao }}
                </b-badge>
                <b-badge variant="light-primary" v-else-if="row.item.situacao_aplicacao === applicationStatus.emPausa">
                  {{ row.item.situacao_aplicacao }}
                </b-badge>
                <b-badge variant="light-info" v-else-if="row.item.situacao_aplicacao === applicationStatus.agendado">
                  {{ row.item.situacao_aplicacao }}
                </b-badge>
                <b-badge variant="light-dark" v-else-if="row.item.situacao_aplicacao === applicationStatus.finalizado">
                  {{ row.item.situacao_aplicacao }}
                </b-badge>
              </div>
            </template>
            <template #cell(data_inicio_aplicacao)="row">
              <div class="text-center">
                {{ moment(row.item.data_inicio_aplicacao, "YYYY-MM-DD").format('DD/MM/YYYY') }}
              </div>
            </template>
            <template #cell(acoes)="row">
              <div class="text-center column-acoes">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="'Enviar por e-mail'"
                  @click="fireModalSendEmail(row.item)"
                  :variant="row.item.situacao_aplicacao === applicationStatus.agendado ||
                            row.item.situacao_aplicacao === applicationStatus.emAndamento ?
                            'outline-primary' : 'outline-secondary'"
                  :class="row.item.situacao_aplicacao === applicationStatus.agendado ||
                          row.item.situacao_aplicacao === applicationStatus.emAndamento ?
                          'margin-right-custom btn-icon btn-acao-tabela' :'margin-right-custom disabled btn-acao-tabela cursor-na'"
                  :disabled="row.item.situacao_aplicacao === applicationStatus.agendado ||
                             row.item.situacao_aplicacao === applicationStatus.emAndamento ?
                             false: true"
                >
                  <feather-icon icon="MailIcon"/>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="'Dados da Aplicação'"
                  @click="abrirDetalhes(row.item)"
                  variant="outline-primary"
                  class="margin-right-custom btn-icon btn-acao-tabela"
                >
                  <feather-icon icon="EyeIcon"/>
                </b-button>
                <b-button
                  v-if="
                    (row.item.situacao_aplicacao === applicationStatus.agendado ||
                    row.item.situacao_aplicacao === applicationStatus.emAndamento ||
                    row.item.situacao_aplicacao === applicationStatus.emPausa) &&
                    row.item.id_criador === idUsuarioLogado
                  "
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  v-b-tooltip.hover.v-secondary
                  v-b-tooltip.hover.bottom="'Editar'"
                  @click="abrirEdicao(row.item)"
                  variant="outline-primary"
                  class="btn-icon btn-acao-tabela"
                >
                  <feather-icon icon="EditIcon"></feather-icon>
                </b-button>
                <b-button
                  v-else
                  variant="outline-secondary"
                  class="disabled btn-acao-tabela"
                >
                  <feather-icon icon="EditIcon"></feather-icon>
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col sm="12">
          <CustomPagination :paginacao="dadosPaginacao" @page-cliked="atualizarPaginaAtual"/>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BForm,
  BRow,
  BBadge,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BTable,
  BTableSimple,
  BTbody,
  BTr,
  BTd,
  BSpinner,
  BAlert,
  BIconPlusCircle,
  BIcon,
  VBTooltip
} from 'bootstrap-vue';
import { modalGenericModel } from "@/libs/sweetalerts";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import moment from 'moment';
import { $tabComponent, $subAccess, $typeStatus } from "../visualizar-aplicacao/tab-components/dados-aplicacao-components/accessEnum.js";
import IconeInfo from "@/assets/custom-icons/info.svg";
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BBadge,
    BFormGroup,
    BFormInput,
    BIconPlusCircle,
    BFormSelect,
    BButton,
    Cleave,
    BTable,
    CustomPagination,
    vSelect,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BAlert,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    moment,
    BIcon,
    VBTooltip
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    return {
      IconeInfo
    };
  },
  data() {
    return {
      idUsuarioLogado: this.$store.state.sessions.userData.usuario.id_usuario,
      emailLogado: this.$store.state.sessions.userData.usuario.email,
      applicationStatus: $typeStatus,
      desabilitarBotao: false,
      busca: {
        termo: '',
      },
      atualizarRegistros: false,
      aplicacoes: [],
      carregandoTabela: true,
      dadosPaginacao: {
        paginaAtual: 1,
        totalLinhas: 1,
        daLinha: 1,
        ateALinha: 1,
        quantidadeLinhasPadrao: 10,
      },
      tabela: {
        semDados: false,
        erroTabela: false,
        tabelaOcupada: false,
        quantidadeLinhasVisualizar: [10, 25, 50, 100],
        ordem: '',
        campoOrdenado: '',
        fields: [
          {key: "nome_aplicacao", label: "Aplicação", sortable: true},
          {key: "razao_social_empresa", label: "Empresa", sortable: true, class: 'w-25 text-center'},
          {key: "situacao_aplicacao", label: "Status", sortable: true, class: 'text-center'},
          {key: "data_inicio_aplicacao", label: "Data", sortable: true, class: 'text-center'},
          {key: "acoes", label: "Ações", class: 'text-center text-center'}
        ],
        items: []
      },
    }
  },
  created() {
    this.buscarDadosTabela();
  },
  methods: {
    abrirDetalhes(item) {
      this.$router.push({ name: 'visualizar-aplicacao', params: { idAplicacao: item.id_aplicacao } });
    },
    abrirEdicao(item) {
      this.$router.push({ name: 'visualizar-aplicacao', params: { idAplicacao: item.id_aplicacao, tabComponent: $tabComponent.dadosAplicacao, subAccess: $subAccess.editar} });
    },
    enviarEmail(item) {
      let rota = this.$api.enviarEmailAplicacao;
      let form = {
         email:        this.emailLogado,
          id_aplicacao: item.id_aplicacao,
      };
      this.$http
        .post(rota,form)
        .then((res) => {
          if (res.status == 200) {
            modalGenericModel(
              'E-mail enviado com sucesso!',
              'Acesse seu e-mail e confira as informações da aplicação',
              require('@/assets/custom-icons/cora-icons/success.png'),
              'Ok'
            )
          }
        }).catch(() => {
          modalGenericModel(
            'E-mail inválido',
            'O e-mail que você digitou parece não estar correto. Favor inserir um endereço de e-mail válido',
            require('@/assets/custom-icons/cora-icons/warning.png'),
            'Entendi'
          )
        });
    },

    moment(date, format) {
      return moment(date, format);
    },
    pesquisar() {
      this.limparPaginacao();
      this.buscarDadosTabela();
    },
    buscarDadosTabela(firstTime = null) {
      if(firstTime && !this.mostrarTabela) {
        this.carregandoTabela = true;
        this.desabilitarBotao = true;
      }

      this.tabela.erroTabela = false;

      this.tabela.semDados = false;
      this.tabela.tabelaOcupada = true;

      this.$http.get(this.$api.cora.aplicacoes, {
        params: this.lidarComParametrosBusca()
      }).then((response) => {

        this.desabilitarBotao = false;

        if(response.status === 200) {
          if(response.data.data.length > 0) {
            this.tabela.items = response.data.data;
            this.tabela.tabelaOcupada = false;
            this.carregandoTabela = false;
            this.IniciarPaginacao(response.data);
            return;
          }
          this.carregandoTabela = false;
          this.tabela.semDados = true;
          this.mostrarTabela = false;
        }
      }).catch(() => {
        this.desabilitarBotao = false;
        this.carregandoTabela = false;
        this.tabela.erroTabela = true;
      });
    },

    ordenarTabela(contextoTabela) {
      this.tabela.campoOrdenado = contextoTabela.sortBy;
      this.tabela.ordem = contextoTabela.sortDesc ? "desc" : "asc";

      this.buscarDadosTabela();
    },

    lidarComParametrosBusca() {
      return {
        "termo": this.busca.termo,
        "colunaNome": this.tabela.campoOrdenado,
        "colunaOrdem": this.tabela.ordem,
        "perPage": this.dadosPaginacao.quantidadeLinhasPadrao,
        "page": this.dadosPaginacao.paginaAtual
      }
    },

    limparFiltros() {
      this.busca.termo = '';

      this.mostrarTabela = false;

      this.limparPaginacao();
    },

    limparPaginacao() {
      this.dadosPaginacao = {
        paginaAtual: 1,
        totalLinhas: 1,
        daLinha: 1,
        ateALinha: 1,
        quantidadeLinhasPadrao: 10,
      };
    },

    IniciarPaginacao(dadosPaginacao) {
      this.dadosPaginacao.daLinha = dadosPaginacao.from;
      this.dadosPaginacao.ateALinha = dadosPaginacao.to;
      this.dadosPaginacao.totalLinhas = dadosPaginacao.total;
      this.dadosPaginacao.paginaAtual = dadosPaginacao.current_page;
      this.dadosPaginacao.quantidadeLinhasPadrao = dadosPaginacao.per_page;
    },

    atualizarPaginaAtual(page) {
      this.dadosPaginacao.paginaAtual = page;
      this.buscarDadosTabela()
    },

    atualizaQuantidadeVisualizar(event) {
      if(!event) {
        this.dadosPaginacao.quantidadeLinhasPadrao = 10;
      }

      this.dadosPaginacao.paginaAtual = 1;
      this.buscarDadosTabela();
    },

    fireModalSendEmail(item) {
      modalGenericModel(
        'Enviar por e-mail',
        'Deseja enviar as informações desta aplicação para o e-mail cadastrado?',
        require('@/assets/custom-icons/cora-icons/help.png'),
        'Sim, enviar',
        'Cancelar'
      ).then((result) => {
        if (result.value) {
          this.enviarEmail(item);
        }
      });
    },
  }
}
</script>
<style>
.btn-acao-tabela {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.9rem !important;
  padding: 0.2rem !important;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .btn-acao-tabela {
    width: 2rem;
    height: 2rem;
    font-size: 0.8rem !important;
    padding: 0.15rem !important;
  }
}
@media (max-width: 767px) {
  .btn-acao-tabela {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.7rem !important;
    padding: 0.1rem !important;
  }
}
.column-acoes {
  min-width: 8rem
}
@media (min-width: 768px) and (max-width: 1199px) {
  .column-acoes {
    min-width: 7rem
  }
}
@media (max-width: 767px) {
  .column-acoes {
    min-width: 6rem
  }
}
.btn-outline-blue {
  border-color: #2772C0!important;
  color: #2772C0!important;
  background-color: white!important;
}
.btn-outline-blue:hover {
  background-color: rgba(0, 137, 235, 0.05) !important;
  box-shadow: none!important;
}
.btn-outline-blue:focus {
  background-color: rgba(0, 137, 235, 0.05) !important;
  box-shadow: none!important;
}
.mr-1-variant {
  margin-right: 0.2rem !important;
}
.mt-n1-variant {
  margin-top: -0.2rem !important;
}
.table thead th {
  vertical-align: middle !important;
}
@media (max-width: 768px) {
  .header-title {
    font-size: 1.25em!important;
  }
}
@media (min-width: 769px) {
  .header-title {
    font-size: 1.5em!important;
  }
}
.margin-right-custom{
  margin-right: 1rem;
}
@media (max-width: 1490px) {
  .margin-right-custom{
    margin-right: 0.1rem;
  }
}
</style>
